import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return <div>
        <div className="footer-sec">
            <div className="footer-sec-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="footer-content">
                                <div className="footer-logo wow animate__slideInDown">
                                    <Link to="/">
                                        <img src={require("../Components/images/footer-logo.png")} alt="" />
                                    </Link>
                                </div> 
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="footer-links d-flex justify-content-center footer-margin">
                                <div className="footer-links-inner">
                                    <h6 className="text-white">Company</h6>
                                    <ul className="text-white">
                                        <li className="wow animate__fadeInUpBig" data-wow-delay="0.20s"><Link to="/about" className="text-white">About us</Link> </li>
                                        <li className="wow animate__fadeInUpBig" data-wow-delay="0.30s"><Link className="text-white" to="/ongoingprojects">On going projects</Link></li>
                                        <li className="wow animate__fadeInUpBig" data-wow-delay="0.40s"><Link to="/resaleprojects" className="text-white">Resale projects</Link> </li>
                                        <li className="wow animate__fadeInUpBig" data-wow-delay="0.50s"><Link to="/construction" className="text-white">Construction</Link></li>
                                        {/* <li className="wow animate__fadeInUpBig" data-wow-delay="0.60s"><Link className="text-white" to="/interiordesigns">Interior Designs</Link> </li> */}
                                        {/* <li className="wow animate__fadeInUpBig" data-wow-delay="0.70s"><Link className="text-white" to="/blog">Blog</Link> </li> */}
                                        <li className="wow animate__fadeInUpBig" data-wow-delay="0.70s"><Link className="text-white" to="/agriculturelands">Agriculture</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="footer-contact footer-margin">
                                <h6 className="text-white">Contact Us</h6>
                                <ul className="text-white">
                                    <li className="wow animate__fadeInUpBig" data-wow-delay="0.20s"><i class="fa fa-envelope-o" aria-hidden="true"></i><a href="mailto:info@bodhahomesinfra.com">info@bodhahomesinfra.com</a></li>
                                    <li className="wow animate__fadeInUpBig" data-wow-delay="0.30s"><i class="fa-solid fa-location-dot"></i>Flat No 101,  Brindavan Apartment,  Plot No 61. Gopanpally, Serilingampally Mandal, RR district  Hyderabad,  Telangana: 502221</li>
                                    <li className="wow animate__fadeInUpBig" data-wow-delay="0.40s"><i class="fa fa-phone" aria-hidden="true"></i><a href="tel:7569922847">7569922847</a><p className="mx-2">|</p><a href="tel:9502701122">9502701122</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right">
                    <p className="text-center text-white">© Copyright 2024 <strong>Bodha</strong>. All rights reserved.</p>
                </div>
            </div>
        </div>
  </div>;
};

export default Footer;
